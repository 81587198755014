import {LocalEventerPod, localEventerPodFactory} from "./eventer-pod-client.ts";
import {useEffect, useState} from "react";

const cache = new Map<string, LocalEventerPod>()
const inFlight = new Map<string, Promise<any>>()

export interface useLocalEventerPodProps {
    pod: string,
}

export function useLocalEventerPod(props: useLocalEventerPodProps) {

    const [pod, setPod] = useState<undefined | LocalEventerPod>(undefined)

    useEffect(() => {

        console.log("useLocalEventerPod.useEffect", props.pod)

        const cached = cache.get(props.pod)
        if (cached) {
            setPod(cached)
            return
        }

        // "inFlight"-Tracking, to avoid parallel inits
        let pending = inFlight.get(props.pod)
        if (pending == undefined) {
            pending = localEventerPodFactory.new()
            inFlight.set(props.pod, pending)
        }

        pending.then((localEventerPod) => {
            if (!cache.has(props.pod)) {
                cache.set(props.pod, localEventerPod)
                setPod(localEventerPod)
            }
            inFlight.delete(props.pod)
        })

    }, [props.pod])

    return { pod: pod }
}