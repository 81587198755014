import {useContext, useEffect, useState} from "react";
import {useLocalEventerPod} from "../../../../../eventer-pod/client/useLocalEventerPod.ts";
import {ResourceWrapper} from "../../../../../eventer-pod/client/ResourceWrapper.ts";
import Session from "../../../../../appfw/authentication/Session.ts";
import {SessionContext} from "../../../../../appfw/authentication/SessionProvider.tsx";


// TODO: later "zustand" to store global states?
let cachedEvents: ResourceWrapper[] | undefined = undefined;

export interface useEventFeedsProps {
    pod: string,
}

export function useEventsFeed(props: useEventFeedsProps) {

    const session = useContext<undefined | null | Session>(SessionContext)
    const { pod } = useLocalEventerPod({ pod: props.pod })

    const [ events, setEvents ] = useState<ResourceWrapper[] | undefined>(cachedEvents)

    useEffect(() => {

        if (events) {
            // already loaded (initially)
            return
        }

        if (!session || !pod) {
            return
        }

        let active = true

        pod.loadPodEvents({ request: { pod: props.pod }, sessionId: session.id })
            .then((events) => {
                if (active) {
                    cachedEvents = events
                    setEvents(events)
                }
            })

        return () => { active = false }

    }, [pod, session?.id])

    return { pod, events }
}