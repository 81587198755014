
let Semantic = {

    Eventer: {
        Predicates: {
            lastSyncAt: {
                uri: `https://schema.eventer.app/lastSyncAt`,
            }
        }
    },

    SchemaOrg: {
        Predicates: {
            eventStatus: {
                uri: "https://schema.org/eventStatus",
                eventScheduled: "https://schema.org/EventScheduled",
            },
        },
    },

    w3: {
        types: {
            dateTime: {
                uri: "http://www.w3.org/2001/XMLSchema#dateTime",
            },
        },
    }
}

export default Semantic