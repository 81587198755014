import {Box, Typography} from "@mui/material";
import {EventCard} from "./EventCard.tsx";
import {useAccount} from "../Accounts.tsx";
import {useEventsFeed} from "./useEventsFeed.ts";

export default function EventsPage() {

    const account = useAccount()
    const eventsFeed = useEventsFeed({pod: account.podName})

    const pod = eventsFeed.pod
    if (pod == undefined || eventsFeed.events == undefined) {
        // TODO: loading spinner
        return <Typography>Lade Events...</Typography>
    }

    return (
        <Box
            sx={{
                // width: '97%',
                display: 'grid',
                // gridTemplateColumns: 'repeat(auto-fill, minmax(min(500px, 100%), 10fr))',
                gap: 2,
                margin: 2,
            }}
        >
            {
                eventsFeed.events?.map((event) => {
                    return <EventCard pod={pod} event={event} key={event.subject} />
                })
            }
        </Box>
    )
}
