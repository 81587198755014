import {SerializedResource} from "../shared/eventer-pod.ts";

export class ResourceWrapper {

    constructor(private readonly resource: SerializedResource) {}

    get subject(): string {
        return this.resource.subject
    }

    getFirstString(pred: string, graphs?: string[]): undefined | string {
        let all = this.getAllStrings(pred, graphs)
        if (!all) return undefined
        if (all.length == 0) {
            return undefined
        }
        return all[0]
    }

    getAllStrings(pred: string, graphs?: string[]): undefined | string[] {
        const objects = this.resource.predicates[pred]
        if (!objects) return undefined
        return (graphs ? objects.filter(o => graphs.includes(o.graph)) : objects)
            // .filter(o => !o.datatype || o.datatype === 'http://www.w3.org/2001/XMLSchema#string')
            .map(o => o.object)
    }
}
