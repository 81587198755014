import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import EventerRoutes from "../../../../EventerRoutes.ts";
import {AccountsProvider, useAccounts} from "./Accounts.tsx";
import {useEffect, useState} from "react";

interface AccountsGuardProps {
    newAccountPath: string;
}

function InnerAccountsGuard({newAccountPath}: AccountsGuardProps) {

    const location = useLocation()
    const navigate = useNavigate()

    const {accounts} = useAccounts()
    const {accountId} = useParams()

    const [valid, setValid] = useState<Boolean>(false)

    useEffect(() => {

        if (accounts === undefined) {
            // loading ...
            return
        }

        // TODO: error handling?

        const path = location.pathname.trim()
        if (accounts.length === 0) {
            console.info("No accounts found ==> redirect to new account page")
            if (path != newAccountPath && path != `${newAccountPath}/`) {
                navigate(newAccountPath, {replace: true})
                return
            }
        }

        const accountsPath = EventerRoutes.accounts.path()
        if (path == accountsPath || path == `${accountsPath}/`) {

            // We are at "/accounts" without being on a subroute, route to "/accounts/:id"
            let accountId = accounts[0].id // TODO: default account?
            let accountPath = EventerRoutes.accounts.account.path(accountId)

            console.log("We are at '/accounts' without being on a subroute, route to: ", accountPath)
            navigate(accountPath, {replace: true})
            return
        }

        // Check, if we hava a valid account
        if (accountId) {
            let account = accounts.find(account => account.id === accountId)
            if (!account) {
                // TODO: not-found page for account?
                console.info(`Invalid accountId: '${accountId}'`)
                navigate(EventerRoutes.accounts.path(), {replace: true})
                return
            }
        }

        // All checks passed, enable outlet
        setValid(true)

    }, [accounts, location.pathname, navigate, accountId, setValid])

    if (valid) {
        return <Outlet/>
    }
}

export default function AccountsGuard({newAccountPath}: AccountsGuardProps) {
    return (
        <AccountsProvider>
            <InnerAccountsGuard newAccountPath={newAccountPath}/>
        </AccountsProvider>
    )
}
