import {EventerWorkerContract, EventerWorkerRequest, EventerWorkerResponse} from "../shared/worker-types.ts";
import {GetResourceParams, LoadPodEventsParams} from "../shared/eventer-pod.ts";
import {ResourceWrapper} from "./ResourceWrapper.ts";

class EventerPodWorker {

    messageCounter = 0;
    pending = new Map<string, (res: EventerWorkerResponse) => void>();

    worker: Worker =
        new Worker(new URL('../worker/eventer-worker.ts', import.meta.url), { type: 'module' })

    constructor() {
        this.worker.onmessage = (event: MessageEvent<EventerWorkerResponse>) => {
            const { messageId } = event.data
            console.log('[client] Received', messageId, event.data)
            const cb = this.pending.get(messageId)
            if (cb) {
                cb(event.data)
                this.pending.delete(messageId)
            }
        }
    }

    call<K extends keyof EventerWorkerContract>(
        type: K,
        payload: Parameters<EventerWorkerContract[K]>[0]
    ): Promise<Awaited<ReturnType<EventerWorkerContract[K]>>> {
        const messageId = `msg-${type}-${this.messageCounter++}`
        return new Promise((resolve, reject) => {
            this.pending.set(messageId, (response) => {
                if (response.error) {
                    reject(new Error(response.error))
                } else {
                    resolve(response.payload)
                }
            })
            const request: EventerWorkerRequest = { type, payload, messageId } as any
            this.worker.postMessage(request)
        })
    }
}

export class LocalEventerPod {

    eventerPodWorker: EventerPodWorker;

    constructor() {
        this.eventerPodWorker = new EventerPodWorker()
    }

    async loadPodEvents(params: LoadPodEventsParams): Promise<ResourceWrapper[]> {
        const serializedResources = await this.eventerPodWorker.call('loadPodEvents', params);
        return serializedResources.map(r => new ResourceWrapper(r));
    }

    async getResource(uri: GetResourceParams): Promise<ResourceWrapper | null> {
        const serializedResource = await this.eventerPodWorker.call('getResource', uri);
        if (serializedResource == null) {
            return null;
        }
        return new ResourceWrapper(serializedResource);
    }
}

export const localEventerPodFactory = {

    new: async (): Promise<LocalEventerPod> => {
        const pod = new LocalEventerPod()
        await pod.eventerPodWorker.call('init', undefined)
        return pod
    }
}